import { CellContext, Row } from '@tanstack/react-table'
import { useState, useEffect, useMemo } from 'react'

import { useLocalization } from 'locales/i18n'
import { OrderType } from 'types/order'
import { ShareWithOrders } from 'types/share'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { Table } from 'ui/@library/data-display/table'
import { useColumnHelper } from 'ui/@library/data-display/table/use-table'
import { Thumbnail } from 'ui/@library/data-display/thumbnail'
import { Loader } from 'ui/@library/feedback/loader'
import { useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey, ModalSize } from 'ui/@library/layout/global-modal/types'
import { useMasterLoadable } from 'ui/@store/master'
import { useShareListLoadable } from 'ui/@store/share-list'
import { SingleMasterShareDetail } from 'ui/master/@components/shares-table/@components/modals/single-master-share-detail'
import { generateNftArtBaseUrl } from 'ui/master/utils'

import { ReviewLimitSell } from '../modals/review-order'

import { FilterButton } from './@components/filter-button'
import { MasterSearchInput } from './@components/master-search-input/master-search-input'
import { ReviewOrderButton } from './@components/review-order-button'
import styles from './styles.module.scss'

export const SharesTable = () => {
  const { t, f } = useLocalization('master.sharesTab')
  const { renderModal } = useGlobalModal()
  const { master } = useMasterLoadable()
  const { shareList, loading: shareListLoading } = useShareListLoadable({
    isAuthUserShares: false,
  })
  const [selectedShares, setSelectedShares] = useState<Set<ShareWithOrders>>(new Set())
  const [searchId, setSearchId] = useState<string>('')
  const [filterMyShares, setFilterMyShares] = useState(false)
  const [filterForSale, setFilterForSale] = useState(false)
  const [filteredShares, setFilteredShares] = useState(shareList)
  const { isAuthenticated } = useMxAuth()

  const handleSellClick = (share: ShareWithOrders) => {
    renderModal({
      modalKey: ModalKey.ReviewLimitSell,
      content: <ReviewLimitSell />,
      payload: {
        numberOfShares: 1,
        sharesToTrade: [share],
        shareUuid: share.uuid,
        orderType: OrderType.LimitAsk,
      },
      enforceAuth: true,
      size: ModalSize.Large,
    })
  }

  const handleCheckboxSelection = (cell: CellContext<ShareWithOrders, unknown>) => {
    if (selectedShares.has(cell.row.original)) {
      selectedShares.delete(cell.row.original)
    } else {
      selectedShares.add(cell.row.original)
    }
    setSelectedShares(new Set(selectedShares))
  }

  const parsePrice = (cell: CellContext<ShareWithOrders, unknown>) => {
    if (isNaN(parseFloat(cell.getValue() as string)))
      return <span className={styles.italizedLabel}>{t('notListed')}</span>
    return f.currency(parseFloat(cell.getValue() as string))
  }

  const parseIndex = (cell: CellContext<ShareWithOrders, unknown>) => {
    return `#${cell.getValue()}`
  }

  const coverImage = (cell: CellContext<ShareWithOrders, unknown>) => {
    if (!master) return <></>
    return (
      <Thumbnail
        thumbnailUrl={master.assets.thumbnail}
        nftArtUrl={generateNftArtBaseUrl(master?.assets.nftArtBaseUrl, cell.getValue() as number)}
      />
    )
  }

  const checkBox = (cell: CellContext<ShareWithOrders, unknown>) => {
    const disabled =
      (!cell.row.original.owned && !cell.row.original.askPrice) ||
      (selectedShares.size > 0 &&
        Array.from(selectedShares)[0]?.owned &&
        !cell.row.original.owned) ||
      (selectedShares.size > 0 &&
        !Array.from(selectedShares)[0]?.owned &&
        cell.row.original.owned) ||
      (cell.row.original.owned && cell.row.original.askPrice !== null)
    return (
      <input
        type='checkbox'
        className={styles.customCheckbox}
        onClick={(e) => e.stopPropagation()}
        onChange={() => handleCheckboxSelection(cell)}
        checked={selectedShares.has(cell.row.original)}
        disabled={disabled}
      />
    )
  }

  const owned = (cell: CellContext<ShareWithOrders, unknown>) => {
    return cell.row.original.owned ? (
      <span
        className={styles.italizedLabel}
        onClick={(e) => {
          e.stopPropagation()
          handleSellClick(cell.row.original)
        }}
      >
        {t('owned')}
      </span>
    ) : (
      <p>--</p>
    )
  }

  const columns = useColumnHelper<ShareWithOrders>([
    {
      id: 'checkBox',
      className: styles.checkButton,
      isDisplayColumn: true,
      cell: checkBox,
    },
    {
      id: 'tokenId',
      className: styles.coverImage,
      cell: coverImage,
    },
    {
      id: 'tokenId',
      label: t('columns.index'),
      className: styles.index,
      cell: parseIndex,
    },
    {
      id: 'askPrice',
      label: t('columns.price'),
      className: styles.price,
      cell: parsePrice,
    },
    {
      id: 'owned',
      label: '',
      className: styles.owned,
      cell: owned,
    },
  ])

  const getSharesFilteredBySearch = useMemo(() => {
    return shareList.filter((shareList) => {
      return shareList.tokenId.toString().search(new RegExp(searchId, 'i')) !== -1
    })
  }, [shareList, searchId])

  useEffect(() => {
    if (searchId) {
      setFilteredShares(getSharesFilteredBySearch)
    } else {
      setFilteredShares(shareList)
    }
    // eslint-disable-next-line
  }, [searchId])

  useEffect(() => {
    if (filterMyShares && filterForSale) {
      setFilteredShares(shareList.filter((share) => share.askPrice && Boolean(share.owned)))
    } else if (filterMyShares) {
      setFilteredShares(shareList.filter((share) => Boolean(share.owned)))
    } else if (filterForSale) {
      setFilteredShares(shareList.filter((share) => share.askPrice))
    } else if (searchId && !isNaN(parseInt(searchId))) {
      setFilteredShares(getSharesFilteredBySearch)
    } else {
      setFilteredShares(shareList)
    }
    // eslint-disable-next-line
  }, [filterForSale, filterMyShares])

  useEffect(() => {
    setFilteredShares(shareList)
    setFilterForSale(false)
    setFilterMyShares(false)
    // eslint-disable-next-line
  }, [shareList])

  if (shareListLoading) return <Loader />

  const rowOnClick = (row: Row<ShareWithOrders>) => {
    renderModal({
      modalKey: ModalKey.SingleMasterShareDetail,
      content: <SingleMasterShareDetail />,
      size: ModalSize.Medium,
      payload: {
        shareUuid: row.original.uuid,
      },
    })
  }

  return (
    <div className={styles.sharesContainer}>
      <div className={styles.royalyShareHeader}>
        <div className={styles.royaltyShareFirstLine}>
          <h1 className={styles.royaltyShareHeading}>{t('bodyText.heading')}</h1>
          <span className={styles.tag}>{t('bodyText.tag')}</span>
        </div>
        <p className={styles.royaltyShareBodyText}>{t('bodyText.body')}</p>
      </div>
      <div className={styles.filterBar}>
        <MasterSearchInput value={searchId} onChange={(value) => setSearchId(value)} />
        {isAuthenticated ? (
          <div className={styles.filterButtonContainer}>
            <FilterButton active={filterMyShares} setActive={setFilterMyShares}>
              {t('filters.myShares')}
            </FilterButton>
            <FilterButton active={filterForSale} setActive={setFilterForSale}>
              {t('filters.forSale')}
            </FilterButton>
          </div>
        ) : (
          <FilterButton active={filterForSale} setActive={setFilterForSale}>
            {t('filters.forSale')}
          </FilterButton>
        )}
        <ReviewOrderButton selectedShares={selectedShares} setSelectedShares={setSelectedShares} />
      </div>
      <Table
        rowClassName={styles.tableRow}
        headerClassName={styles.tableHeader}
        data={filteredShares}
        columns={columns}
        rowOnClick={rowOnClick}
      />
    </div>
  )
}
