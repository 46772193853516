import { CitationLink } from 'ui/@library/utils/citation-link/citation-link'

import styles from './styles.module.scss'

type WhyMusicCardProps = {
  imageSrc: string
  title: string
  description: string
  sourceLink?: string
}

export const WhyMusicCard = ({ imageSrc, title, description, sourceLink }: WhyMusicCardProps) => {
  return (
    <div className={styles.whyMusicItem}>
      <img src={imageSrc} alt='Why invest' />
      <h2 className={styles.whyMusicItemTitle}>{title}</h2>
      <p className={styles.whyMusicItemDescription}>
        {description}
        {sourceLink && <CitationLink href={sourceLink} number={1} />}
      </p>
    </div>
  )
}
