import { AxiosError } from 'axios'
import { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { ReactComponent as CoinStrip } from 'assets/svgs/coin-strip-lg.svg'
import { ReactComponent as CoinStripSm } from 'assets/svgs/coin-strip-sm.svg'
import DecorCoin from 'assets/svgs/decor-coin.svg'
import { CustomErrorCodes } from 'constants/error-codes'
import { Paths } from 'constants/paths'
import { REWARD_AMOUNT } from 'constants/referral'
import { useLocalization } from 'locales/i18n'
import { fetchReferralCodeValidation } from 'repositories/mx-api'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { TrustPilotBox } from 'ui/@components/trust-pilot-box'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { FullPageError } from 'ui/@library/errors'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { TermsAndConditions } from 'ui/@library/utils/terms-and-conditions'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import { RewardStep } from './@components/reward-step'
import { SignUpInviteeForm } from './@components/sign-up-invitee-form/sign-up-invitee-form'
import { WhyMusicSection } from './@components/why-music-section'
import styles from './styles.module.scss'

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const WelcomeInvitee = () => {
  const { t, f } = useLocalization('invitee')
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const { authUser } = useAuthUserLoadable()

  const [referrerName, setReferrerName] = useState<string | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const hasErrorMessage =
    error instanceof AxiosError &&
    (error.response?.data?.code === CustomErrorCodes.INVALID_REFERRAL_CODE ||
      error.response?.data?.code ===
        CustomErrorCodes.REFERRAL_CODES_CANNOT_BE_USED_BY_EXISTING_ACCOUNTS)

  const rewardAmount = f.currency(REWARD_AMOUNT, { compact: 'short' })

  if (authUser) {
    if (authUser.referralCode !== undefined && authUser.referralCode !== null) {
      navigate(Paths.REFERRAL)
    } else {
      navigate(Paths.ACCOUNT.MAIN)
    }
  }

  const handleReferralCodeValidation = async (referralCode: string) => {
    try {
      const { name } = await fetchReferralCodeValidation(referralCode)
      setReferrerName(capitalizeFirstLetter(name))
    } catch (error) {
      setError(error as AxiosError)
    }
  }

  useEffectOnce(() => {
    const referralCode = queryParams.get('referral_code')

    if (referralCode) {
      handleReferralCodeValidation(referralCode)
    }
  })

  if (error) {
    return (
      <FullPageError
        error={error}
        message={hasErrorMessage ? error?.response?.data?.message : undefined}
      />
    )
  }

  if (!referrerName) return <FullPageLoader pageTitle={t('pageTitle')} />

  return (
    <RouteWrapper
      title={t('pageTitle', { referrerName })}
      description={t('pageDescription', { referrerName })}
    >
      <div className={styles.inviteePage}>
        <div className={styles.firstFold}>
          <img src={DecorCoin} alt='decorCoin' className={styles.decorCoin} />
          <div className={styles.container}>
            <div className={styles.textWrapper}>
              <h1 className={styles.title}>{t('header.title', { referrerName, rewardAmount })}</h1>
              <p className={styles.description}>{t('header.description')}</p>

              <div className={styles.rewardSteps}>
                <RewardStep stepNumber={1} stepText={t('rewardSteps.step1')} />
                <RewardStep stepNumber={2} stepText={t('rewardSteps.step2')} />
                <RewardStep stepNumber={3} stepText={t('rewardSteps.step3', { rewardAmount })} />
              </div>
            </div>

            <CoinStripSm className={styles.coinStripSm} />

            <WhyMusicSection />

            <div className={styles.formContainer}>
              <SignUpInviteeForm />
              <TrustPilotBox centered />
            </div>
          </div>
        </div>

        <CoinStrip className={styles.coinStripLg} />

        <div id='termsAndConditions'>
          <TermsAndConditions localizationKey='invitee.termsAndConditions' value={rewardAmount} />
        </div>
      </div>
    </RouteWrapper>
  )
}
