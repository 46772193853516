import { format, subYears } from 'date-fns'
import { useEffect, useState } from 'react'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { TransactionHistoryItemType } from 'types/transaction'
import {
  TableServerPaginated,
  useColumnDefinition,
  useColumnHelper,
} from 'ui/@library/data-display/table'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'
import { PillSelect, PillSelectOption } from 'ui/@library/inputs/pill-select'
import { EmptyStatePlaceholder } from 'ui/@library/layout/empty-state-placeholder'
import { GraphicsToShow } from 'ui/@library/layout/empty-state-placeholder/states'

import { MobileTable } from './@components/mobile-table'
import styles from './styles.module.scss'
import { TransactionHistoryTableColumns } from './types'
import { useTransactionHistory } from './use-transaction-history'

const PAGE_SIZE = 25

const enum DateInput {
  From = 'from',
  To = 'to',
}

const enum FilterType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  RoyaltyPayout = 'royaltyPayout',
  Buy = 'buy',
  Sell = 'sell',
  Others = 'others',
}

export const TransactionHistory = () => {
  const { t } = useLocalization('myAssets.transactionHistory')
  const [activePage, setActivePage] = useState(1)
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<string[]>([])

  const {
    transactionsList,
    transactionTypes,
    startDate,
    endDate,
    isLastPage,
    loading,
    fetchNextPage,
    setStartDate,
    setEndDate,
    setTransactionTypes,
  } = useTransactionHistory(PAGE_SIZE)

  const columnDefinition = useColumnDefinition<TransactionHistoryTableColumns>({
    tableData: transactionsList,
    styles,
    translationKey: 'myAssets.transactionHistory.columns',
  })

  const columns = useColumnHelper<TransactionHistoryTableColumns>(columnDefinition)

  const PillSelectOptions: PillSelectOption[] = [
    {
      value: FilterType.Deposit,
      label: t(`filters.pillSelect.${FilterType.Deposit}`),
    },
    {
      value: FilterType.Withdrawal,
      label: t(`filters.pillSelect.${FilterType.Withdrawal}`),
    },
    {
      value: FilterType.RoyaltyPayout,
      label: t(`filters.pillSelect.${FilterType.RoyaltyPayout}`),
    },
    {
      value: FilterType.Buy,
      label: t(`filters.pillSelect.${FilterType.Buy}`),
    },
    {
      value: FilterType.Sell,
      label: t(`filters.pillSelect.${FilterType.Sell}`),
    },
    {
      value: FilterType.Others,
      label: t(`filters.pillSelect.${FilterType.Others}`),
    },
  ]

  const handleTypeFilter = (values: string[]) => {
    const sanitizedValues = values.map((value) => {
      switch (value) {
        case FilterType.Buy:
          return [TransactionHistoryItemType.Buy, TransactionHistoryItemType.ImoBuy]
        case FilterType.Sell:
          return [TransactionHistoryItemType.Sell, TransactionHistoryItemType.ImoSell]
        case FilterType.RoyaltyPayout:
          return [TransactionHistoryItemType.RoyaltyPayout]
        case FilterType.Others:
          return [
            ...Object.values(TransactionHistoryItemType).filter(
              (type) =>
                type !== TransactionHistoryItemType.Buy &&
                type !== TransactionHistoryItemType.ImoBuy &&
                type !== TransactionHistoryItemType.Sell &&
                type !== TransactionHistoryItemType.ImoSell &&
                type !== TransactionHistoryItemType.Deposit &&
                type !== TransactionHistoryItemType.Withdrawal &&
                type !== TransactionHistoryItemType.RoyaltyPayout,
            ),
          ]
        default:
          return value
      }
    })

    setTransactionTypes(sanitizedValues.flat() as TransactionHistoryItemType[])
    setSelectedTransactionTypes(values)
  }

  const handleDateInput = (date: string, type: DateInput) => {
    switch (type) {
      case DateInput.From:
        setStartDate(date)
        if (endDate && new Date(date) > new Date(endDate)) setEndDate(date)
        break
      case DateInput.To:
        setEndDate(date)
        break
      default:
        break
    }
  }

  const handleClearFilters = () => {
    setStartDate(format(subYears(new Date(), 1), 'yyyy-MM-dd'))
    setEndDate(format(new Date(), 'yyyy-MM-dd'))
    setTransactionTypes([])
    setSelectedTransactionTypes([])
  }

  useEffect(() => {
    // Reset active page when filters change
    setActivePage(1)
  }, [startDate, endDate, transactionTypes])

  const emptyStateCondition =
    !loading && !transactionsList.length && !transactionTypes.length && !startDate && !endDate

  const emptyStateWithFiltersCondition =
    !loading && !transactionsList.length && (transactionTypes.length || startDate || endDate)

  const today = new Date().toISOString().split('T')[0]

  return (
    <>
      <h1 className={styles.pageTitle}>{t('heading')}</h1>
      <div className={styles.filtersContainer}>
        <div className={styles.dateInputContainer}>
          <div className={styles.inputWrapper}>
            <label htmlFor='startDate'>{t('filters.datepicker.from')}</label>
            <input
              title='startDate'
              name='startDate'
              type='date'
              value={startDate || ''}
              className={styles.dateInput}
              onChange={(e) => handleDateInput(e.target.value, DateInput.From)}
              max={endDate || today}
            />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor='endDate'>{t('filters.datepicker.to')}</label>
            <input
              title='endDate'
              name='endDate'
              type='date'
              value={endDate || ''}
              className={styles.dateInput}
              onChange={(e) => handleDateInput(e.target.value, DateInput.To)}
              min={startDate || ''}
              max={today}
            />
          </div>
        </div>
        <PillSelect
          label={t('filters.pillSelect.label')}
          options={PillSelectOptions}
          onChange={handleTypeFilter}
          selectedOptions={selectedTransactionTypes}
          setSelectedOptions={handleTypeFilter}
          disabled={loading}
        />
      </div>
      {transactionsList.length > 0 && (
        <>
          <TableServerPaginated
            activePage={activePage}
            data={transactionsList}
            columns={columns}
            pageSize={PAGE_SIZE}
            loading={loading}
            isLastPage={isLastPage}
            hideInMobile
            setActivePage={setActivePage}
            fetchNextPage={fetchNextPage}
          />
          <MobileTable
            tableData={transactionsList}
            loading={loading}
            isLastPage={isLastPage}
            fetchNextPage={fetchNextPage}
          />
        </>
      )}
      {emptyStateCondition && (
        <EmptyStatePlaceholder
          message={t('emptyState.message')}
          button={t('emptyState.button')}
          path={Paths.EXCHANGE.MAIN}
          image={GraphicsToShow.Transactions}
        />
      )}
      {emptyStateWithFiltersCondition && (
        <div className={styles.emptyStateWithFilters}>
          <EmptyStatePlaceholder
            message={t('emptyState.noDataWithFilters')}
            image={GraphicsToShow.TransactionsFilter}
          />
          <Button
            variant='outline'
            onClick={handleClearFilters}
            className={styles.clearFiltersButton}
          >
            {t('emptyState.clearFilters')}
          </Button>
        </div>
      )}
      {loading && transactionsList.length === 0 && (
        <Spinner className={styles.transactionHistorySpinner} />
      )}
    </>
  )
}
