import { useEffect } from 'react'
import {
  atom,
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'

import { fetchAffiliateProgram } from 'repositories/mx-api/queries/fetch-affiliate-program'
import { AffiliateProgram } from 'types/affiliate-program'

export const affiliateProgramUuidState = atom<string | null>({
  key: 'affiliateProgramUuid',
  default: null,
})

export const affiliateProgramQuery = selector<AffiliateProgram | null>({
  key: 'affiliateProgramQuery',
  get: async ({ get }) => {
    const affiliateProgramUuid = get(affiliateProgramUuidState)
    if (!affiliateProgramUuid) return null
    return fetchAffiliateProgram(affiliateProgramUuid)
  },
})

export const useAffiliateProgramLoadable = (
  affiliateProgramUuid?: string,
  { forceRefetch = false } = {},
) => {
  const setAffiliateProgramUuid = useSetRecoilState(affiliateProgramUuidState)
  const { state, contents } = useRecoilValueLoadable(affiliateProgramQuery)
  const refetch = useRecoilRefresher_UNSTABLE(affiliateProgramQuery)

  useEffect(() => {
    if (affiliateProgramUuid) setAffiliateProgramUuid(affiliateProgramUuid)
  }, [affiliateProgramUuid, setAffiliateProgramUuid])

  useEffect(() => {
    if (forceRefetch) refetch()
    // eslint-disable-next-line
  }, [])

  return {
    affiliateProgram: state === 'hasValue' ? contents : null,
    loading: state === 'loading',
    error: state === 'hasError' && contents,
    refetch,
  }
}
