import { useMxApi } from '../mx-api'

export const useValidateUserRemovalRequest = () => {
  const [{ loading, error, response }, execute] = useMxApi(
    {},
    {
      manual: true,
    },
  )

  const validateUserRemovalRequest = async (verificationCode: string) => {
    await execute({
      url: '/user-removal-requests/validate',
      method: 'POST',
      params: {
        verification_code: verificationCode,
      },
    })
  }

  return {
    loading,
    error,
    validateUserRemovalRequest,
    response,
  }
}
