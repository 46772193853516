import { useAuth0 } from '@auth0/auth0-react'
import { Preferences } from '@capacitor/preferences'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

import { ampli } from 'ampli'
import { CapacitorPreferences, LocalStorage } from 'constants/local-storage'
import { upsertAuthUser, upsertDeviceToken } from 'repositories/mx-api'
import { AccessStatus, AuthUser } from 'types/auth-user'
import { usePlatform } from 'ui/@hooks/use-platform'
import { useAuthUserState } from 'ui/@store/auth-user'

export const useInitAuthUser = () => {
  const [currentAuthUser, setCurrentAuthUser] = useAuthUserState()
  const [mxAuthError, setMxAuthError] = useState<AxiosError | null>(null)
  const [isAuthUserInitialised, setIsAuthUserInitialised] = useState(false)
  const { isAuthenticated } = useAuth0()
  const { isNative } = usePlatform()

  const updateCurrentAuthUser = async () => {
    // Clear referral code from local storage for older clients
    localStorage.removeItem(LocalStorage.REFERRAL_CODE)
    const affiliateProgramName = localStorage.getItem(LocalStorage.AFFILIATE_PROGRAM_NAME)
    localStorage.removeItem(LocalStorage.AFFILIATE_PROGRAM_NAME)
    try {
      const currentAuthUser = await upsertAuthUser(undefined, affiliateProgramName ?? undefined)
      await handleUpsertDeviceToken(currentAuthUser)
      setCurrentAuthUser(currentAuthUser)
    } catch (error) {
      setMxAuthError(error as AxiosError)
    }
  }

  const handleUpsertDeviceToken = async (currentAuthUser: AuthUser) => {
    if (isNative && currentAuthUser?.accessStatus === AccessStatus.EarlyAccess) {
      const deviceToken = await Preferences.get({ key: CapacitorPreferences.DEVICE_TOKEN })
      const isDeviceTokenSyncedForSession = sessionStorage.getItem('isDeviceTokenSyncedForSession')
      if (deviceToken.value && isDeviceTokenSyncedForSession !== 'true') {
        try {
          await upsertDeviceToken(deviceToken.value)
          sessionStorage.setItem('isDeviceTokenSyncedForSession', 'true')
        } catch (error) {
          sessionStorage.setItem('isDeviceTokenSyncedForSession', 'false')
          Sentry.captureException(error)
        }
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) updateCurrentAuthUser()
    setIsAuthUserInitialised(true)
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated && currentAuthUser) setCurrentAuthUser(null)
    // eslint-disable-next-line
  }, [isAuthenticated, currentAuthUser])

  useEffect(() => {
    if (currentAuthUser) {
      ampli.identify(currentAuthUser.uuid)
      Sentry.setUser({
        id: currentAuthUser.uuid,
        username: currentAuthUser.email,
      })

      // setLanguage(currentAuthUser?.preferredLocale)
    }
    // eslint-disable-next-line
  }, [currentAuthUser])

  return { isAuthUserInitialised, error: mxAuthError }
}
