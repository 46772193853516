import { Trans } from 'react-i18next'

import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg'
import { useLocalization } from 'locales/i18n'
import { resendUserRemovalVerificationEmail } from 'repositories/mx-api'
import { useSignout } from 'ui/@hooks/use-signout'
import { useTimer } from 'ui/@hooks/use-timer'
import { IconWrapper } from 'ui/@library/data-display/icon-wrapper'
import { Button } from 'ui/@library/inputs/button'
import { StaticPageWrapper } from 'ui/@library/layout/static-page-wrapper'

import styles from './styles.module.scss'

const TIMER_DURATION = 5 // seconds

type UserRemovalRequestValidatedProps = {
  email?: string
}
export const UserRemovalRequestCreated = ({ email }: UserRemovalRequestValidatedProps) => {
  const { t } = useLocalization('deleteAccount.requestCreated')
  const signOut = useSignout()
  const { timeLeft, resetTimer } = useTimer({ duration: TIMER_DURATION })

  const handleResendEmail = () => {
    resendUserRemovalVerificationEmail()
    resetTimer()
  }

  return (
    <StaticPageWrapper>
      <div className={styles.pageContents}>
        <IconWrapper className={styles.iconSpacing}>
          <MailIcon />
        </IconWrapper>
        <h1 className={styles.title}>{t('heading')}</h1>
        <p className={styles.description}>
          <Trans
            i18nKey='deleteAccount.requestCreated.description'
            values={{ email }}
            components={[<strong key='email' />]}
          />
        </p>
        <div className={styles.resendPasswordContainer}>
          <p className={styles.description}>{t('resetPasswordTitle')}</p>
          <p className={styles.description}>{t('resetPasswordDescription')}</p>
        </div>

        <div className={styles.buttonContainer}>
          {timeLeft === 0 ? (
            <Button onClick={handleResendEmail}>{t('resend')}</Button>
          ) : (
            <p className={styles.timer}>
              <span>{timeLeft}</span>
            </p>
          )}
          <Button
            variant='ghost'
            onClick={() => {
              signOut()
            }}
          >
            {t('signOut')}
          </Button>
        </div>
      </div>
    </StaticPageWrapper>
  )
}
