import { format, subYears } from 'date-fns'
import { isEqual, camelCase } from 'lodash'
import { useEffect, useState } from 'react'

import { TextFormats } from 'locales/constants'
import { useLocalization } from 'locales/i18n'
import { fetchTransactionHistory } from 'repositories/mx-api/queries/fetch-transaction-history'
import { TransactionHistory, TransactionHistoryItemType } from 'types/transaction'
import { TransactionHistoryTableColumns } from 'ui/my-assets/@components/transaction-history/types'

export const useTransactionHistory = (pageSize: number) => {
  const { f, t } = useLocalization('myAssets.transactionHistory.transactionType')

  const [data, setData] = useState<TransactionHistory | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const [startDate, setStartDate] = useState<string | null>(
    format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  )
  const [endDate, setEndDate] = useState<string | null>(format(new Date(), 'yyyy-MM-dd'))

  const [transactionTypes, setTransactionTypes] = useState<TransactionHistoryItemType[]>([])
  const [transactionsList, setTransactionsList] = useState<TransactionHistoryTableColumns[]>([])

  const handleFetchTransactionHistory = async (nextPageToken?: string, append = false) => {
    setLoading(true)
    try {
      const data = await fetchTransactionHistory(
        startDate,
        endDate,
        transactionTypes,
        nextPageToken,
        pageSize,
      )
      if (data) {
        setData(data)
      }
      setLoading(false)
      const newTransactions = data.page.map((item) => ({
        date: f.date(item.executedAt, TextFormats.DATE.DATE_WITH_TIME) || '-',
        transactionType: t(camelCase(item.transactionType)) || '-',
        description: item.description || '-',
        tokenId: item.tokenId ? `#${item.tokenId}` : '-',
        amount: isNaN(item.amount) ? '-' : f.currency(item.amount),
        fees: isNaN(item.fees) ? '-' : f.currency(item.fees),
        total: isNaN(item.total) ? '-' : f.currency(item.total, { showSign: true }),
      }))
      if (append) {
        setTransactionsList((prevArray) => {
          if (isEqual(prevArray, newTransactions)) {
            return prevArray
          }
          return [...prevArray, ...newTransactions]
        })
      } else {
        setTransactionsList(newTransactions)
      }
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    handleFetchTransactionHistory()
    // eslint-disable-next-line
  }, [startDate, endDate, transactionTypes])

  const fetchNextPage = async () => {
    if (data) {
      await handleFetchTransactionHistory(data.pageInfo.nextPageToken, true)
    }
  }

  const isLastPage = !loading && !data?.pageInfo.nextPageToken

  return {
    transactionsList,
    transactionTypes,
    startDate,
    endDate,
    isLastPage,
    loading,
    error,
    fetchNextPage,
    setStartDate,
    setEndDate,
    setTransactionTypes,
  }
}
