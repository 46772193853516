import { useLocalization } from 'locales/i18n'
import { AffiliateProgram } from 'types/affiliate-program'

import { AffiliateForm } from './@components/affiliate-form'
import { AffiliateStep } from './@components/affiliate-step'
import styles from './styles.module.scss'

export type AffiliateInstructionsProps = {
  affiliateProgram: AffiliateProgram
}

export const AffiliateInstructions = ({ affiliateProgram }: AffiliateInstructionsProps) => {
  const { t, f } = useLocalization('affiliate')
  const rewardAmount = f.currency(affiliateProgram.rewardAmount, { compact: 'short' })

  const AffiliateSteps = [
    {
      title: t('affiliateSteps.step1.title'),
      description: t('affiliateSteps.step1.description'),
      component: <AffiliateForm />,
    },
    {
      title: t('affiliateSteps.step2.title'),
      description: t('affiliateSteps.step2.description'),
    },
    {
      title: t('affiliateSteps.step3.title'),
      description: t('affiliateSteps.step3.description', { rewardAmount }),
    },
  ]

  return (
    <div className={styles.stepsContainer}>
      {AffiliateSteps.map((step, index) => (
        <AffiliateStep
          key={index}
          number={index + 1}
          title={step.title}
          description={step.description}
          component={step.component}
        />
      ))}
    </div>
  )
}
