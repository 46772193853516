import { ReactComponent as Star } from 'assets/icons/star.svg'
import { ExternalLinks } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

export type AffiliateTermsProps = {
  terms: string[]
}

export const AffiliateTerms = ({ terms }: AffiliateTermsProps) => {
  const { t } = useLocalization('affiliate.terms')
  const termsToCheck = ['T&C', 'terms and conditions', 'Terms and Conditions']

  return (
    <div className={styles.container} id='affiliate-terms'>
      <h2 className={styles.title}>{t('title')}</h2>
      <div className={styles.terms}>
        {terms.map((term, index) => {
          const foundTerm = termsToCheck.find((termToCheck) => term.includes(termToCheck))
          const parts = foundTerm ? term.split(foundTerm) : [term]
          return (
            <div key={index} className={styles.term}>
              <Star className={styles.icon} />
              <p key={index} className={styles.text}>
                {parts[0]}
                {termsToCheck.some((termToCheck) => term.includes(termToCheck)) && (
                  <a
                    href={ExternalLinks.TERMS_AND_CONDITIONS}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={styles.termsHyperLink}
                  >
                    {foundTerm}
                  </a>
                )}
                {parts[1]}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
