import styles from './styles.module.scss'

type AffiliateStepProps = {
  number: number
  title: string
  description: string
  component?: React.ReactNode
}

export const AffiliateStep = ({ number, title, description, component }: AffiliateStepProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.number}>
        <span>{number}</span>
      </div>
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        {component && component}
      </div>
    </div>
  )
}
