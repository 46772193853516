import styles from './styles.module.scss'

type CitationLinkProps = {
  href: string
  openInNewTab?: boolean
  number: number
}

export const CitationLink = ({ href, openInNewTab = true, number }: CitationLinkProps) => {
  return (
    <sup>
      <a
        className={styles.sourceLink}
        href={href}
        target={openInNewTab ? '_blank' : '_self'}
        rel='noopener noreferrer'
      >
        [{number}]
      </a>
    </sup>
  )
}
