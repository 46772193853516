import { ReactComponent as ShieldCheck } from 'assets/svgs/shield-check.svg'
import { useLocalization } from 'locales/i18n'
import { updatePassword } from 'repositories/mx-api'
import { useEffectOnce } from 'ui/@hooks/use-effect-once'
import { useTimer } from 'ui/@hooks/use-timer'
import { Button } from 'ui/@library/inputs/button'
import { Divider } from 'ui/@library/layout/divider'
import { ModalContent } from 'ui/@library/layout/global-modal'
import { useAuthUserLoadable } from 'ui/@store/auth-user'

import styles from './styles.module.scss'

const TIMER_DURATION = 12 // seconds

export const UpdatePasswordModal = () => {
  const { t } = useLocalization('account.updatePassword.updateModal')
  const { authUser } = useAuthUserLoadable()
  const { timeLeft, resetTimer } = useTimer({ duration: TIMER_DURATION, startTimer: true })

  useEffectOnce(() => {
    updatePassword()
  })

  const handleResendEmail = () => {
    updatePassword()
    resetTimer()
  }

  return (
    <ModalContent className={styles.updatePasswordContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.titleWrapper}>
          <ShieldCheck />
          <h1 className={styles.title}>{t('title')}</h1>
        </div>
        <div className={styles.messageWrapper}>
          <p className={styles.message}>{t('message')}</p>
          <p className={styles.email}>{authUser?.email}</p>
        </div>
        <Divider />
        {timeLeft === 0 ? (
          <Button variant='ghost' width='fluid' onClick={handleResendEmail}>
            {t('buttonText')}
          </Button>
        ) : (
          <p className={styles.timer}>{timeLeft}</p>
        )}
      </div>
    </ModalContent>
  )
}
