import { useEffect, useRef } from 'react'

import { useLocalization } from 'locales/i18n'
import { Loader } from 'ui/@library/feedback/loader'
import { Divider } from 'ui/@library/layout/divider'

import { TransactionHistoryTableColumns } from '../../types'

import styles from './styles.module.scss'

type mobileTableProps = {
  tableData: Array<TransactionHistoryTableColumns>
  loading: boolean
  isLastPage: boolean
  fetchNextPage: () => void
}

export const MobileTable = ({
  tableData,
  loading,
  isLastPage,
  fetchNextPage,
}: mobileTableProps) => {
  const { t } = useLocalization('myAssets.transactionHistory')

  const observer = useRef<IntersectionObserver>()
  const lastElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting && !isLastPage) {
        fetchNextPage()
      }
    })
    if (lastElementRef.current) observer.current.observe(lastElementRef.current)
  }, [loading, isLastPage, fetchNextPage])

  return (
    <div className={styles.mobileTableContainer}>
      {tableData.map((item, index) => {
        return (
          <div
            className={styles.mobileRowWrapper}
            key={`${item.description}-${item.date}-${item.tokenId}`}
            ref={index === tableData.length - 1 ? lastElementRef : null}
          >
            <p className={styles.date}>{item.date}</p>
            <div className={styles.flexWrapper}>
              <p>{item.description}</p>
              <p className={styles.total}>{item.total}</p>
            </div>
            <div className={styles.flexWrapper}>
              <p>{item.tokenId}</p>
              <p>{item.transactionType}</p>
            </div>
            <Divider className={styles.divider} />
          </div>
        )
      })}
      {loading ? <Loader /> : <p className={styles.endOfList}>{t('endOfList')}</p>}
    </div>
  )
}
