import { AddFundsAmount } from 'constants/funds'
import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'
import { ModalContent, useGlobalModal } from 'ui/@library/layout/global-modal'
import { ModalKey } from 'ui/@library/layout/global-modal/types'
import { AddFundsConfirm, PaymentMethodModal } from 'ui/@modals/add-funds'
import { useCardsLoadable } from 'ui/@store/cards'
import { useWalletLoadable } from 'ui/@store/wallet'

import styles from './styles.module.scss'

export type DirectCheckoutMaxLimitPromptProps = {
  amount: number
}

export const DirectCheckoutMaxLimitPrompt = () => {
  const { t, f } = useLocalization(
    'master.overviewTab.sidebar.trader.modals.directCheckoutMaxLimitPrompt',
  )
  const { cards, loading: cardsLoading } = useCardsLoadable({ forceRefetch: true })
  const { setDisplayModal, renderModal, payload: modalPayload } = useGlobalModal()
  const { wallet } = useWalletLoadable()

  if (!wallet) return null

  const accountBalance = wallet?.primaryBalance

  const handleModalClose = () => {
    setDisplayModal(false)
  }

  const handleAddFunds = () => {
    if (cards.length !== 0 && accountBalance !== undefined)
      renderModal({
        modalKey: ModalKey.AddFundsConfirm,
        content: <AddFundsConfirm />,
        payload: { prefilledAmount: Math.max(modalPayload?.amount, AddFundsAmount.MAX) },
      })
    else renderModal({ modalKey: ModalKey.PaymentMethod, content: <PaymentMethodModal /> })
  }

  return (
    <ModalContent>
      {cardsLoading ? (
        <Spinner width='fluid' />
      ) : (
        <div className={styles.container}>
          <div className={styles.topWrapper}>
            <h1 className={styles.title}>{t('title')}</h1>
            <p className={styles.subtitle}>
              {t('description', { maxAmount: f.currency(AddFundsAmount.MAX) })}
            </p>
            <div className={styles.informationContainer}>
              <p className={styles.highlightedText}>
                {t('fundsNeeded', { amount: f.currency(modalPayload?.amount) })}
              </p>
              <p>{t('balance', { amount: f.currency(accountBalance) })}</p>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button width='fluid' variant='primary' onClick={handleAddFunds}>
              {t('deposit')}
            </Button>
            <Button width='fluid' variant='ghost' onClick={handleModalClose}>
              {t('cancel')}
            </Button>
          </div>
        </div>
      )}
    </ModalContent>
  )
}
