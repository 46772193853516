import { AffiliateProgram } from 'types/affiliate-program'

import { sanitizeAffiliateProgramFromRaw } from '../@santizers/affiliate-program'
import { MxApi } from '../mx-api'

export const fetchAffiliateProgram = async (
  affiliateProgramName: string,
): Promise<AffiliateProgram> => {
  const { data } = await MxApi.get(`/affiliates/programs/${affiliateProgramName}`)

  return sanitizeAffiliateProgramFromRaw(data)
}
