import {
  TransactionHistory,
  TransactionHistoryItemType,
  TransactionHistoryRaw,
} from 'types/transaction'

import { sanitizeTransactionHistoryRaw } from '../@santizers/transaction'
import { MxApi } from '../mx-api'

// eslint-disable-next-line
const paramsSerializer = (params: any) => {
  let options = ''

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        options += `${key}=${element}&`
      }
    } else if (!value) {
      continue
    } else {
      options += `${key}=${value}&`
    }
  }

  return options.slice(0, -1)
}

export const fetchTransactionHistory = async (
  startDate: string | null,
  endDate: string | null,
  transactionTypes: TransactionHistoryItemType[],
  nextPageToken?: string,
  pageSize = 25,
): Promise<TransactionHistory> => {
  const { data } = await MxApi.get<TransactionHistoryRaw>('/auth-users/me/transaction-history', {
    params: {
      start_date: startDate,
      end_date: endDate,
      page_size: pageSize,
      type: transactionTypes,
      next_page_token: nextPageToken,
    },
    paramsSerializer,
  })
  return sanitizeTransactionHistoryRaw(data)
}
