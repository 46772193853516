import { useRef, useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { ReactComponent as SpotifyIcon } from 'assets/icons/spotify.svg'
import { useLocalization } from 'locales/i18n'
import { MasterWithImoAndMarket } from 'types/master'
import { useWindowSize } from 'ui/@hooks/use-window-size'

import { PriceChange } from '../price-change/price-change'
import { Yield } from '../yield'

import styles from './styles.module.scss'

type MasterDetailsStripProps = {
  master: MasterWithImoAndMarket
}

const MIN_TRUNCATION = 3
const MARQUEE_SPEED = 30

const getTruncatedName = (name: string, overflowValue: number) => {
  let charactersToTruncate = Math.ceil(overflowValue / MIN_TRUNCATION)
  if (charactersToTruncate < MIN_TRUNCATION) charactersToTruncate = MIN_TRUNCATION
  let truncatedName = name
  if (name.length > charactersToTruncate) {
    truncatedName = truncatedName.slice(0, -charactersToTruncate) + '...'
  }
  return truncatedName
}

export const MasterMarketDetailsStrip = ({ master }: MasterDetailsStripProps) => {
  const { f } = useLocalization('')
  const { windowSize, viewports } = useWindowSize()

  const [artistNameOverflow, setArtistNameOverflow] = useState(0)
  const [artistName, setArtistName] = useState(master.artist.name)
  const [masterNameOverflow, setMasterNameOverflow] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  const artistDetailRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const masterNameRef = useRef<HTMLParagraphElement>(null)
  const autoScrollThreshold = viewports.isTablet && !viewports.isMobile ? 0.5 : 0.7

  // handles artist name overflow
  useEffect(() => {
    if (parentRef.current && artistDetailRef.current) {
      setArtistNameOverflow(
        Math.max(artistDetailRef.current.clientWidth - parentRef.current.clientWidth, 0),
      )
    }
    if (artistNameOverflow > 0) {
      setArtistName(getTruncatedName(master.artist.name, artistNameOverflow))
    }
    // eslint-disable-next-line
  }, [artistDetailRef, windowSize.width, artistNameOverflow])

  // handles master name overflow
  useEffect(() => {
    if (parentRef.current && masterNameRef.current) {
      const overflow =
        masterNameRef.current.clientWidth - parentRef.current.clientWidth * autoScrollThreshold
      if (overflow > 0) setMasterNameOverflow(overflow)
    }
    // eslint-disable-next-line
  }, [masterNameRef, autoScrollThreshold, windowSize.width])

  const handleHover = () => {
    setIsHovered(!isHovered)
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.infoWrapper} ref={parentRef}>
          <div className={styles.songInfo}>
            <div className={styles.subLine}>
              {masterNameOverflow > 0 ? (
                isHovered && viewports.isDesktop ? (
                  <div className={styles.autoScrollWrapper} onMouseLeave={handleHover}>
                    <Marquee speed={MARQUEE_SPEED} onCycleComplete={handleHover}>
                      <p
                        className={`${styles.subHeading} ${styles.autoScroll}`}
                        ref={masterNameRef}
                      >
                        {master.name}
                      </p>
                    </Marquee>
                  </div>
                ) : (
                  <p className={styles.subHeading} ref={masterNameRef} onMouseEnter={handleHover}>
                    {getTruncatedName(master.name, masterNameOverflow)}
                  </p>
                )
              ) : (
                <p className={styles.subHeading} ref={masterNameRef}>
                  {master.name}
                </p>
              )}

              {master.metrics.platformStreams.spotify && (
                <div className={styles.spotifyStreams}>
                  <SpotifyIcon className={styles.spotifyIcon} />
                  <p>{f.number(master.metrics.platformStreams.spotify, { compact: 'short' })}</p>
                </div>
              )}
            </div>
            <div ref={artistDetailRef}>
              <p className={styles.artistName}>{artistName}</p>
            </div>
            <Yield master={master} />
            <div className={styles.priceContainer}>
              <p className={styles.subHeading}>{f.currency(master.market.lastPrice)}</p>
              <PriceChange master={master} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
