import { format } from 'date-fns'

import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserAccountingTransactionHistory = async (
  startDate: string,
  endDate: string,
) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/transaction-history', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'blob',
  })

  downloadBlobAsFile(
    data,
    `MasterExchange_Transaction_History_${format(new Date(), 'yyyyMMdd')}.csv`,
  )
}
