import { useEffect, useState } from 'react'

type useTimerConfig = {
  duration: number // timer duration in seconds
  startTimer?: boolean // start timer on mount
}

export const useTimer = ({ duration, startTimer }: useTimerConfig) => {
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    if (startTimer) {
      setTimeLeft(duration)
    }
  }, [startTimer, duration])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [timeLeft])

  const resetTimer = () => {
    setTimeLeft(duration)
  }

  return { timeLeft, resetTimer }
}
