// import { AccountDeletion } from '../@components/account-deletion'
import { AddFunds } from '../@components/add-funds'
import { Kyc } from '../@components/kyc'
import { LegalName } from '../@components/legal-name'
import { UpdatePassword } from '../@components/update-password'
import { UserInfo } from '../@components/user-info'
import { WithdrawFunds } from '../@components/withdraw-funds'

export const AccountDetails = () => {
  return (
    <>
      <UserInfo />
      <Kyc />
      <AddFunds />
      <WithdrawFunds />
      <UpdatePassword />
      <LegalName />
      {/* <AccountDeletion /> */}
    </>
  )
}
