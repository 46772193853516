import whyInvest1 from 'assets/images/why-invest-1.png'
import whyInvest2 from 'assets/images/why-invest-2.png'
import whyInvest3 from 'assets/images/why-invest-3.png'
import { ExternalLinks } from 'constants/external-links'
import { useLocalization } from 'locales/i18n'

import { WhyMusicCard } from '../why-music-card'

import styles from './styles.module.scss'

export const WhyMusicSection = () => {
  const { t } = useLocalization('invitee.whyInvest')

  const WhyMusicCards = [
    {
      imageSrc: whyInvest1,
      title: t('reason1.title'),
      description: t('reason1.description'),
    },
    {
      imageSrc: whyInvest2,
      title: t('reason2.title'),
      description: t('reason2.description'),
    },
    {
      imageSrc: whyInvest3,
      title: t('reason3.title'),
      description: t('reason3.description'),
      sourceLink: ExternalLinks.GOLDMAN_SACHS_SOURCE,
    },
  ]

  return (
    <div className={styles.WhyMusicSection}>
      <h1 className={styles.sectionTitle}>{t('title')}</h1>
      <div className={styles.whyMusicList}>
        {WhyMusicCards.map((card, index) => (
          <WhyMusicCard
            key={index}
            imageSrc={card.imageSrc}
            title={card.title}
            description={card.description}
            sourceLink={card.sourceLink}
          />
        ))}
      </div>
    </div>
  )
}
