import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useLocalization } from 'locales/i18n'
import { AffiliateProgram } from 'types/affiliate-program'

import styles from './styles.module.scss'

export type CampaignInfoProps = {
  affiliateProgram: AffiliateProgram
}

export const CampaignInfo = ({ affiliateProgram }: CampaignInfoProps) => {
  const { campaign: campaignName } = useParams()
  const { t, f } = useLocalization('affiliate')

  const rewardAmount = f.currency(affiliateProgram.rewardAmount, { compact: 'short' })

  return (
    <div className={styles.campaignInfoContainer}>
      {affiliateProgram.logo && (
        <img
          src={affiliateProgram.logo}
          alt={`${campaignName}-logo`}
          className={styles.campaignLogo}
        />
      )}

      {affiliateProgram.isActive ? (
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{affiliateProgram.title ?? t('title')}</h1>
          <p className={styles.info}>{affiliateProgram.description ?? t('info')}</p>
          <p className={styles.rewardInfo}>
            <Trans
              i18nKey='affiliate.rewardInfo'
              values={{
                rewardAmount: rewardAmount,
              }}
              components={[
                // eslint-disable-next-line
                <a key='affiliate-terms-link' href='#affiliate-terms' className={styles.link} />,
              ]}
            />
          </p>
        </div>
      ) : (
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{affiliateProgram.inactiveCopy.title}</h1>
          <p className={styles.info}>{affiliateProgram.inactiveCopy.description}</p>
        </div>
      )}
    </div>
  )
}
