import { Master } from 'types/master'
import { Thumbnail } from 'ui/@library/data-display/thumbnail'
import { generateNftArtBaseUrl } from 'ui/master/utils'

import styles from './styles.module.scss'

type MasterInfoProps = {
  master: Master
  tokenId: number
}

export const MasterInfo = ({ master, tokenId }: MasterInfoProps) => {
  return (
    <div className={styles.masterInfo}>
      <Thumbnail
        thumbnailUrl={master.assets.thumbnail}
        nftArtUrl={generateNftArtBaseUrl(master.assets.nftArtBaseUrl, tokenId)}
      />
      <div className={styles.masterTitle}>
        <h1 className={styles.masterName}>{master.name}</h1>
        <p className={styles.artistName}>{master.artist.name}</p>
      </div>
    </div>
  )
}
