import { AxiosPromise } from 'axios'

import { AuthUser, AuthUserRaw } from 'types/auth-user'

import { sanitizeAuthUserFromRaw } from '../@santizers/auth-user'
import { MxApi } from '../mx-api'

let requestCache: AxiosPromise<AuthUserRaw> | undefined
let requestUrl = '/auth-users'

const makeUpsertAuthUserRequest = async (referral_code?: string, acquisition_source?: string) => {
  if (requestCache) {
    return requestCache
  }

  if (referral_code) requestUrl += `?referral_code=${referral_code}`
  if (acquisition_source) requestUrl += `?acquisition_source=${acquisition_source}`

  const promise = MxApi.post<AuthUserRaw>(requestUrl)
    .then((response) => {
      requestCache = undefined
      return response
    })
    .catch((error) => {
      requestCache = undefined
      throw error
    })

  requestCache = promise

  return promise
}

export const upsertAuthUser = async (
  referral_code?: string,
  acquisition_source?: string,
): Promise<AuthUser> => {
  const { data } = await makeUpsertAuthUserRequest(referral_code, acquisition_source)

  return sanitizeAuthUserFromRaw(data)
}
