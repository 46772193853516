export const Paths = {
  HEALTH: '/health',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  INVITE_SIGN_UP: '/invite-sign-up',
  SIGN_OUT: '/sign-out',
  REDIRECTION_3DS: '/3ds-redirection',
  HOME: '/',
  ACCOUNT: {
    MAIN: '/account',
    DETAILS: 'details',
    NOTIFICATION_SETTINGS: 'notification-settings',
  },
  DELETE_ACCOUNT: {
    MAIN: '/delete-account',
    VALIDATE: 'validate',
  },
  EXCHANGE: {
    MAIN: '/exchange',
    ALL: 'all',
    BROWSE_SONGS: 'browse-songs',
    IMO: 'imos',
  },
  MASTER: {
    MAIN: '/master',
    UUID: '/:uuid',
    IMO: '/imo',
    MARKET: '/market',
    TAB: '/:tab',
  },
  ONBOARDING: {
    MAIN: '/onboarding/',
    EMAIL_VERIFICATION: 'email-verification',
    ADD_PERSONAL_DETAILS: 'personal-details',
    VERIFY_IDENTITY: 'verify-identity',
    ADD_CARD: 'add-card',
    ADD_FUNDS: 'add-funds',
  },
  KYC: '/kyc',
  MY_ASSETS: {
    MAIN: '/my-assets',
    TRANSACTIONS: 'transaction-history',
    OVERVIEW: 'overview',
    STATEMENTS: 'statements',
  },
  AFFILIATE: {
    MAIN: '/affiliate',
    CAMPAIGN: '/:campaign',
  },
  MAINTENANCE: '/maintenance',
  LOGIN_METHOD_CONFLICT: '/user-conflict',
  UNSUPPORTED_CLIENT: '/unsupported-client',
  SIGNUPS_DISABLED: '/signups-disabled',
  LOGGED_OUT: '/logged-out',
  ERROR: '/error',
  REFERRAL: '/refer-a-friend',
  WELCOME_INVITEE: '/invite',
} as const

export const QueryParams = {
  REFERRER: 'referrer',
  REDIRECT: 'redirect',
  [Paths.KYC]: {
    PAYMENT_FLOW: 'paymentFlow',
  },
  [Paths.LOGGED_OUT]: {
    METHOD: 'method',
  },
} as const

export const Redirects = {
  LOGIN_METHOD_CONFLICT: {
    KEY: 'login-method-conflict',
    PATH: Paths.LOGIN_METHOD_CONFLICT,
  },
  SIGNUPS_DISABLED: {
    KEY: 'signups-disabled',
    PATH: Paths.SIGNUPS_DISABLED,
  },
} as const
