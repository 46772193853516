import camelcaseKeys from 'camelcase-keys'

import {
  TransactionRaw,
  ShareTransactionRaw,
  TransactioHistoryItemRaw,
  TransactionHistoryRaw,
} from 'types/transaction'

export const sanitizeTransactionRaw = (raw: TransactionRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }), createdAt: new Date(raw.created_at) }
}

export const sanitizeShareTransactionRaw = (raw: ShareTransactionRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }), createdAt: new Date(raw.created_at) }
}

export const sanitizeTransactionHistoryItemRaw = (raw: TransactioHistoryItemRaw) => {
  return { ...camelcaseKeys(raw, { deep: true }), executedAt: new Date(raw.executed_at) }
}

export const sanitizeTransactionHistoryRaw = (raw: TransactionHistoryRaw) => {
  return {
    ...camelcaseKeys(raw, { deep: true }),
    page: raw.page.map(sanitizeTransactionHistoryItemRaw),
  }
}
