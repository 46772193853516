import { useLocalization } from 'locales/i18n'
import { ImoStatus } from 'types/imo'
import { MasterWithImoAndMarket } from 'types/master'
import { getRoyaltyYield } from 'ui/master/utils'

import styles from './styles.module.scss'

type YieldProps = {
  master: MasterWithImoAndMarket
}

export const Yield = ({ master }: YieldProps) => {
  const { f, t } = useLocalization('exchange.spotlight.yield')

  const royaltyYield = getRoyaltyYield(master)

  const isImoCompleted = master.imo.status === ImoStatus.Completed

  return (
    <div>
      {royaltyYield > 0 && !master.isNewRelease && (
        <p className={styles.yield}>
          <span>{f.percentage(royaltyYield)}</span>
          <span className={styles.description}> {t('annualYield')}</span>
        </p>
      )}
      {((royaltyYield <= 0 && isImoCompleted) || (master.isNewRelease && isImoCompleted)) && (
        <p className={styles.yield}>{t('annualYieldAfterMaturity')}</p>
      )}
    </div>
  )
}
