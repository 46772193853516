import { format, subYears } from 'date-fns'
import { Form, Formik } from 'formik'
import { SchemaOf, object, string } from 'yup'

import { useLocalization } from 'locales/i18n'
import {
  fetchAuthUserAccountingSummary,
  fetchAuthUserDepositsAndWithdrawals,
  fetchAuthUserAccountingTransactionHistory,
  fetchAuthUserDivlyTaxReport,
} from 'repositories/mx-api'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'
import { Select } from 'ui/@library/inputs/select'

import styles from './styles.module.scss'

export enum StatementType {
  Summary = 'summary',
  Transactions = 'transactions',
  DepositsAndWithdrawals = 'depositsAndWithdrawals',
  DivlyTaxReport = 'divlyTaxReport',
}

type DownloadFormValues = {
  startDate: string
  endDate: string
  statementType: string
}

const initialValues: DownloadFormValues = {
  startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  statementType: StatementType.Transactions,
}

export const Statements = () => {
  const { t } = useLocalization('myAssets.statements.downloadSection')
  const today = new Date().toISOString().split('T')[0]

  const statementTypeOptions = [
    {
      value: StatementType.Transactions,
      label: t('inputs.statementType.label.transactions'),
    },
    {
      value: StatementType.Summary,
      label: t('inputs.statementType.label.accountSummary'),
    },
    {
      value: StatementType.DepositsAndWithdrawals,
      label: t('inputs.statementType.label.depositsAndWithdrawals'),
    },
    {
      value: StatementType.DivlyTaxReport,
      label: t('inputs.statementType.label.divlyReport'),
    },
  ]

  const validationSchema: SchemaOf<DownloadFormValues> = object({
    startDate: string()
      .required(t('inputs.startDate.required'))
      .test(
        'startDate',
        t('inputs.startDate.invalid', {
          // dd/mm/yyyy to match the date format in the input
          today: new Date().toLocaleDateString('en-GB'),
        }),
        (value) => {
          if (value) {
            const startDate = new Date(value)
            const today = new Date()
            return startDate <= today
          }
          return true
        },
      ),
    endDate: string()
      .required(t('inputs.endDate.required'))
      .test(
        'endDate',
        t('inputs.endDate.invalid', {
          // dd/mm/yyyy to match the date format in the input
          today: new Date().toLocaleDateString('en-GB'),
        }),
        (value, { parent }) => {
          if (value) {
            const startDate = new Date(parent.startDate)
            const endDate = new Date(value)
            return startDate <= endDate
          }
          return true
        },
      ),
    statementType: string().required(t('inputs.statementType.required')),
  })

  const handleDownload = async (values: DownloadFormValues) => {
    switch (values.statementType) {
      case StatementType.Transactions:
        await fetchAuthUserAccountingTransactionHistory(values.startDate, values.endDate)
        return
      case StatementType.Summary:
        await fetchAuthUserAccountingSummary(values.startDate, values.endDate)
        return
      case StatementType.DepositsAndWithdrawals:
        await fetchAuthUserDepositsAndWithdrawals(values.startDate, values.endDate)
        return
      case StatementType.DivlyTaxReport:
        await fetchAuthUserDivlyTaxReport(values.startDate, values.endDate)
        return
      default:
        return
    }
  }

  return (
    <div className={styles.statementsContainer}>
      <h1 className={styles.pageTitle}>{t('title')}</h1>
      <p className={styles.description}>{t('description')}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleDownload}
        validateOnBlur
        validateOnMount
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className={styles.downloadForm}>
            <div className={styles.inputContainer}>
              <div className={styles.dateInput}>
                <Input
                  name='startDate'
                  type='date'
                  label={t('inputs.startDate.label')}
                  value={values.startDate}
                  placeholder={new Date().toJSON().slice(0, 10)}
                  pattern='\d{4}-\d{2}-\d{2}'
                  max={values.endDate || today}
                />
              </div>
              <div className={styles.dateInput}>
                <Input
                  className={styles.dateInput}
                  name='endDate'
                  type='date'
                  label={t('inputs.endDate.label')}
                  value={values.endDate}
                  placeholder={new Date().toJSON().slice(0, 10)}
                  pattern='\d{4}-\d{2}-\d{2}'
                  disabled={!values.startDate}
                  min={values.startDate || ''}
                  max={today}
                />
              </div>
              <Select
                customClassName={styles.statementSelector}
                name='statementType'
                label={t('inputs.statementType.label.title')}
                options={statementTypeOptions}
                currentValue={values.statementType}
                placeholder={t('inputs.statementType.label.placeholder')}
              />
            </div>
            <Button
              className={styles.downloadButton}
              type='submit'
              loading={isSubmitting}
              disabled={!isValid}
            >
              {t('inputs.submit')}
            </Button>
          </Form>
        )}
      </Formik>
      <hr />
    </div>
  )
}
