import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useLocalization } from 'locales/i18n'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { Button } from 'ui/@library/inputs/button'
import { Input } from 'ui/@library/inputs/input'

import styles from './styles.module.scss'

type FormValues = {
  email: string
}

const initialValues: FormValues = {
  email: '',
}

export const AffiliateForm = () => {
  const { t } = useLocalization('affiliate.form')
  const { signUp } = useSignInAndSignUp()

  const validationSchema = Yup.object({
    email: Yup.string().email(t('emailInvalid')).required(t('errors.invalid')),
  })

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await signUp(values.email)
    setSubmitting(false)
  }

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className={styles.formContainer}>
          <Input
            name='email'
            type='email'
            placeholder={t('email.placeholder')}
            autoComplete='email'
          />
          <Button width='fluid' type='submit'>
            {t('buttonText')}
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
