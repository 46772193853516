import { format } from 'date-fns'

import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserDepositsAndWithdrawals = async (startDate: string, endDate: string) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/deposits-and-withdrawals', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'arraybuffer',
  })

  downloadBlobAsFile(
    data,
    `MasterExchange_Deposits_and_Withdrawals_${format(new Date(), 'yyyyMMdd')}.pdf`,
  )
}
