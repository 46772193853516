export const LocalStorage = {
  ACQUISITION_SOURCE: 'acquisitionSource',
  I18: 'i18nextLng',
  DISMISSED_MESSAGES: 'dismissedMessages',
  REFERRAL_CODE: 'referralCode',
  AFFILIATE_PROGRAM_NAME: 'affiliateProgramName',
} as const

export const CapacitorPreferences = {
  DEVICE_TOKEN: 'deviceToken',
  ASKED_NOTIFICATION_PERMISSION: 'askedNotificationPermission',
} as const
