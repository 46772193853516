import { Trans } from 'react-i18next'

import { useLocalization } from 'locales/i18n'
import { useSignout } from 'ui/@hooks/use-signout'
import { Button } from 'ui/@library/inputs/button'
import { StaticPageWrapper } from 'ui/@library/layout/static-page-wrapper'

import styles from './styles.module.scss'

type UserRemovalRequestValidatedProps = {
  email?: string
}

export const UserRemovalRequestValidated = ({ email }: UserRemovalRequestValidatedProps) => {
  const { t } = useLocalization('deleteAccount.requestValidated')

  const signout = useSignout()

  return (
    <StaticPageWrapper>
      <div className={styles.pageContents}>
        <h1 className={styles.header}>{t('header')}</h1>
        <p className={styles.description}>
          <Trans
            i18nKey='deleteAccount.requestValidated.description'
            values={{ email }}
            components={[<strong key='email' />]}
          />
        </p>
        <Button
          onClick={() => {
            signout()
          }}
        >
          {t('signOut')}
        </Button>
      </div>
    </StaticPageWrapper>
  )
}
