export const LocaleFiles = {
  ACCOUNT: 'account',
  ADD_FUNDS: 'add-funds',
  BANNER: 'banner',
  COMPONENTS: 'components',
  DELETE_ACCOUNT: 'delete-account',
  EXCHANGE: 'exchange',
  FEEDBACK: 'feedback',
  INVITEE: 'invitee',
  KYC: 'kyc',
  MAINTENANCE: 'maintenance',
  MASTER: 'master',
  MODALS: 'modals',
  MY_ASSETS: 'my-assets',
  ONBOARDING: 'onboarding',
  ORDER: 'order',
  REFERRAL: 'referral',
  AFFILIATE: 'affiliate',
  REFERRAL_PAUSED: 'referral-paused',
  UNSUPPORTED_CLIENT: 'unsupported-client',
  WITHDRAW_FUNDS: 'withdraw-funds',
  SIGNUPS_DISABLED: 'signups-disabled',
} as const

export const TextFormats = {
  DATE: {
    DATE_WITH_TIME_LONG: 'd MMM yyyy, HH:mm',
    DATE_WITH_TIME: 'yyyy-MM-dd, HH:mm',
    DATE_WITH_MONTH_AND_TIME: 'dd MMM, HH:mm',
    DATE: 'yyyy-MM-dd',
    DATE_ALT: 'd MMM yyyy',
    DATE_WITH_MONTH_LONG: 'dd MMM yyyy',
    DATE_WITH_MONTH: 'dd MMM',
    QUARTER_AND_YEAR: 'QQQ yy',
    MONTH_WITH_YEAR: 'MMM yyyy',
  },
  CURRENCY: {
    LOCALE: 'en-US',
    OPTIONS: {
      style: 'currency',
      currency: 'EUR',
    },
    COMPACT: {
      SHORT: {
        notation: 'compact',
        compactDisplay: 'short',
      },
      LONG: {
        notation: 'compact',
        compactDisplay: 'long',
      },
    },
  },
  NUMBER: {
    LOCALE: 'en-US',
    OPTIONS: {
      style: 'decimal',
    },
    OPTIONS_COMPACT_SHORT: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
    },
    OPTIONS_COMPACT_LONG: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'long',
    },
  },
  PERCENTAGE: {
    LOCALE: 'en-US',
    OPTIONS: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
} as const
