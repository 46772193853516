import { useLocalization } from 'locales/i18n'

import styles from './styles.module.scss'

export type LiveTagProps = {
  className?: string
}

export const LiveTag = ({ className }: LiveTagProps) => {
  const { t } = useLocalization('exchange.spotlight.hero')

  return (
    <div className={`${styles.liveNow} ${className}`}>
      <div className={styles.liveStatus} />
      <div>{t('liveNow')}</div>
    </div>
  )
}
