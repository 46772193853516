import styles from './styles.module.scss'

type ThumbnailProps = {
  thumbnailUrl: string
  nftArtUrl?: string
}

export const Thumbnail = ({ thumbnailUrl, nftArtUrl }: ThumbnailProps) => {
  return (
    <div className={styles.thumbnailContainer}>
      <img src={thumbnailUrl} alt='album art cover' className={styles.thumbnail} />
      {nftArtUrl && (
        <img
          src={nftArtUrl}
          alt='nft art cover'
          className={styles.nftArt}
          onError={(e) => {
            e.currentTarget.style.display = 'none'
          }}
        />
      )}
    </div>
  )
}
