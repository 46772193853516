import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

import styles from './styles.module.scss'

export type PillSelectOption = {
  value: string
  label: string
}

type PillSelectProps = {
  label?: string
  options: PillSelectOption[]
  selectedOptions: string[]
  disabled?: boolean
  setSelectedOptions: (selectedOptions: string[]) => void
  onChange: (selectedOptions: string[]) => void
}

export const PillSelect = ({
  label,
  options,
  selectedOptions,
  disabled,
  setSelectedOptions,
  onChange,
}: PillSelectProps) => {
  const handleOptionClick = (value: string) => {
    if (selectedOptions.includes(value)) {
      const updatedSelectedOptions = selectedOptions.filter((option) => option !== value)
      onChange(updatedSelectedOptions)
      setSelectedOptions(updatedSelectedOptions)
    } else {
      const updatedSelectedOptions = [...selectedOptions, value]
      onChange(updatedSelectedOptions)
      setSelectedOptions(updatedSelectedOptions)
    }
  }

  return (
    <div className={styles.pillSelectContainer}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.pillOptionsList}>
        {options.map((option) => {
          const isSelected = selectedOptions.includes(option.value)
          return (
            <div
              key={option.value}
              className={clsx(
                styles.pillWrapper,
                isSelected && styles.active,
                disabled && styles.disabled,
              )}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
              <AnimatePresence mode='wait'>
                {isSelected && (
                  <motion.div
                    initial={{ opacity: 0, x: -10, rotate: -180 }}
                    animate={{ opacity: 1, x: 0, rotate: 0 }}
                    exit={{ opacity: 0, x: -10, rotate: -180 }}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                    className={styles.closeIcon}
                  >
                    <CloseIcon />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )
        })}
      </div>
    </div>
  )
}
