import { format } from 'date-fns'

import { MxApi } from '../mx-api'
import { downloadBlobAsFile } from '../utils'

export const fetchAuthUserDivlyTaxReport = async (startDate: string, endDate: string) => {
  const { data } = await MxApi.get('/auth-users/me/accounting/divly-tax-report', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    responseType: 'blob',
  })

  downloadBlobAsFile(data, `MasterExchange_Divly_Tax_Report_${format(new Date(), 'yyyyMMdd')}.csv`)
}
