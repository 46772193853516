import { useLocalization } from 'locales/i18n'
import { ImoStatus } from 'types/imo'
import { MasterWithImoAndMarket } from 'types/master'
import { LiveTag } from 'ui/exchange/@components/exchange-card'
import { Yield } from 'ui/exchange/@components/exchange-card/yield'
import { getOwnersFromOwnerList } from 'ui/master/utils'

import styles from './styles.module.scss'

export type MasterCardProps = {
  master: MasterWithImoAndMarket
}

export const MasterCard = ({ master }: MasterCardProps) => {
  const { t } = useLocalization('affiliate.masterCard')
  if (!master) return null

  const masterOwners = master.metaData.ownedByWithAvatars
  const isImoActive = master.imo.status === ImoStatus.Active

  return (
    <div className={styles.container}>
      {isImoActive && <LiveTag className={styles.liveTag} />}
      <img src={master.assets.placeholder} alt={master.name} className={styles.masterAsset} />
      <div className={styles.masterDetailsContainer}>
        <h2 className={styles.masterName}>{master.name}</h2>
        <p className={styles.artistName}>{master.artist.name}</p>
        {masterOwners.length > 0 && (
          <p className={styles.owner}>
            {t('broughtBy', {
              ownersList: getOwnersFromOwnerList(masterOwners),
            })}
          </p>
        )}
      </div>
      {isImoActive && (
        <div className={styles.imoDetailsContainer}>
          <h3 className={styles.imoHeading}>{t('imo')}</h3>
          <div className={styles.imoMetrics}>
            <p>{t('priceInfo,', { price: master.imo.price })}</p>
            <Yield master={master} />
          </div>
        </div>
      )}
    </div>
  )
}
