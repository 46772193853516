/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Paths } from 'constants/paths'
import { useLocalization } from 'locales/i18n'
import { useValidateUserRemovalRequest } from 'repositories/mx-api/hooks/use-validate-account-deletion-request'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { useMxAuth } from 'ui/@hooks/use-mx-auth'
import { useSignInAndSignUp } from 'ui/@hooks/use-signin-and-signup'
import { useSignout } from 'ui/@hooks/use-signout'
import { FullPageError } from 'ui/@library/errors'
import { ErrorWithAction } from 'ui/@library/errors/error-with-action'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'

const VERIFICATION_CODE_PARAM = 'verification_code'

export const UserRemovalRequestVerification = () => {
  const { t } = useLocalization('deleteAccount.requestVerificationCode')
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const verificationCode = queryParams.get(VERIFICATION_CODE_PARAM)
  const { validateUserRemovalRequest, error, response } = useValidateUserRemovalRequest()
  const signOut = useSignout()
  const { signIn } = useSignInAndSignUp()
  const { isAuthenticated } = useMxAuth()

  useEffect(() => {
    if (response?.status === 204) {
      navigate(Paths.HOME)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const validateVerificationCode = async () => {
    if (verificationCode) {
      try {
        await validateUserRemovalRequest(verificationCode)
      } catch (error) {
        //
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (verificationCode) {
        validateVerificationCode()
      } else {
        window.location.href = Paths.HOME
      }
    }
    // eslint-disable-next-line
  }, [verificationCode, isAuthenticated])

  if (error) {
    return (
      <RouteWrapper title={t('error')}>
        <ErrorWithAction
          header={t('heading')}
          message={error.response?.data.message}
          secondaryButtonTitle={t('signOut')}
          secondaryButtonOnClick={signOut}
        />
      </RouteWrapper>
    )
  } else if (!isAuthenticated) {
    return (
      <RouteWrapper title={t('pageTitle')}>
        <ErrorWithAction
          header={t('heading')}
          message={t('message')}
          buttonTitle={t('signIn')}
          buttonOnClick={signIn}
          showSupportLink={false}
        />
      </RouteWrapper>
    )
  }

  return <FullPageLoader />
}
