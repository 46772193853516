import { useLocation, useParams } from 'react-router-dom'

import { ReactComponent as CoinStrip } from 'assets/svgs/coin-strip-lg.svg'
import { LocalStorage } from 'constants/local-storage'
import { useLocalization } from 'locales/i18n'
import { RouteWrapper } from 'ui/@components/route-wrapper'
import { FullPageError, NotFound } from 'ui/@library/errors'
import { FullPageLoader } from 'ui/@library/feedback/full-page-loader'
import { useAffiliateProgramLoadable } from 'ui/@store/affiliate-program'
import { WhyMusicSection } from 'ui/welcome-invitee/@components/why-music-section'
import { capitalizeFirstLetter } from 'ui/welcome-invitee/welcome-invitee'

import { AffiliateInstructions } from './@components/affiliate-instructions'
import { AffiliateTerms } from './@components/affiliate-terms'
import { CampaignInfo } from './@components/campaign-info'
import { MasterCard } from './@components/master-card'
import styles from './styles.module.scss'

export const AffiliateProgram = () => {
  const { t } = useLocalization('affiliate')
  const location = useLocation()

  const { uuid: programName } = useParams()

  const {
    loading,
    error,
    affiliateProgram,
    refetch: refetchAffiliateProgram,
  } = useAffiliateProgramLoadable(programName, {
    forceRefetch: true,
  })

  const handleRefresh = () => {
    refetchAffiliateProgram()
  }

  if (loading) return <FullPageLoader variant='secondary' />
  if (error?.response?.status === 404 || !affiliateProgram) return <NotFound />
  if (error) return <FullPageError error={error} />

  if (affiliateProgram) {
    localStorage.setItem(LocalStorage.AFFILIATE_PROGRAM_NAME, affiliateProgram.name)
  }

  const isActive = affiliateProgram.isActive

  return (
    <RouteWrapper
      title={t('pageTitle', { campaignName: capitalizeFirstLetter(affiliateProgram.name) })}
      description={t('pageDescription')}
      pageUrl={`${process.env.REACT_APP_WEB_APP_BASE_URL}${location.pathname}`}
      onRefresh={handleRefresh}
      allowsBackNavigation
      noPadding
    >
      <>
        <div className={styles.pageContainer}>
          <CampaignInfo affiliateProgram={affiliateProgram} />
          {isActive && (
            <div>
              <div className={styles.signUpContainer}>
                <AffiliateInstructions affiliateProgram={affiliateProgram} />
                <MasterCard master={affiliateProgram.master} />
              </div>
              <CoinStrip className={styles.coinStrip} />
              <WhyMusicSection />
            </div>
          )}
        </div>
        {isActive && <AffiliateTerms terms={affiliateProgram.termsAndConditions} />}
      </>
    </RouteWrapper>
  )
}
