import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg'
import { useLocalization } from 'locales/i18n'
import { Spinner } from 'ui/@library/feedback/spinner'
import { Button } from 'ui/@library/inputs/button'

import styles from './styles.module.scss'

type TableProps<TData> = {
  data: TData[]
  columns: ColumnDef<TData>[]
  hideInMobile?: boolean
  activePage: number
  pageSize: number
  loading: boolean
  isLastPage: boolean
  setActivePage: (page: number) => void
  rowOnClick?: (row: Row<TData>) => void
  fetchNextPage: () => Promise<void>
}

export const TableServerPaginated = <TData extends object>({
  data,
  columns,
  hideInMobile,
  activePage,
  pageSize,
  loading,
  isLastPage,
  setActivePage,
  rowOnClick,
  fetchNextPage,
}: TableProps<TData>) => {
  const { t } = useLocalization('components.tableServerPagination')

  const [activePageData, setActivePageData] = useState<TData[]>([])
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    const allPages = Math.ceil(data.length / pageSize)
    setTotalPages(allPages)
  }, [data, pageSize])

  useEffect(() => {
    const start = pageSize * (activePage - 1)
    const end = start + pageSize
    setActivePageData(data.slice(start, end))
  }, [activePage, data, pageSize])

  const handleNextPage = async () => {
    if (!isLastPage) {
      await fetchNextPage()
      setActivePage(activePage + 1)
    } else {
      setActivePage(activePage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    }
  }

  const getPaginationText = () => {
    const start = pageSize * (activePage - 1) + 1
    const end = Math.min(start + pageSize - 1, data.length)
    return `${start}-${end} ${t('of')} ${isLastPage ? data.length : t('many')}`
  }

  const table = useReactTable({
    data: activePageData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false,
    manualPagination: true,
  })

  return (
    <div
      className={clsx(
        styles.tableServerPaginatedContainer,
        hideInMobile && styles.hideInMobile,
        loading && styles.tableServerPaginatedLoading,
      )}
    >
      {loading && <Spinner className={styles.tableServerPaginatedSpinner} />}
      <div className={styles.tableContainer}>
        <div className={styles.flexTable}>
          <div className={styles.thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id} className={styles.tr}>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, {
                        ...header.getContext(),
                        key: header.id,
                      }),
                )}
              </div>
            ))}
          </div>
          <div className={styles.tbody}>
            {table.getRowModel().rows.map((row) => (
              <div key={row.id} className={styles.tr} onClick={() => rowOnClick && rowOnClick(row)}>
                {row
                  .getVisibleCells()
                  .map((cell) =>
                    flexRender(cell.column.columnDef.cell, { ...cell.getContext(), key: cell.id }),
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.pagination}>
        <Button
          className={styles.serverPaginationButton}
          variant='small'
          onClick={handlePreviousPage}
          disabled={activePage === 1}
        >
          <ChevronLeft />
          <p>{t('previous')}</p>
        </Button>
        <p className={styles.serverPaginationText}>{getPaginationText()}</p>
        <Button
          className={styles.serverPaginationButton}
          variant='small'
          onClick={handleNextPage}
          disabled={isLastPage && activePage >= totalPages}
          color='primary'
        >
          <p>{t('next')}</p>
          <ChevronRight />
        </Button>
      </div>
    </div>
  )
}
